import { useState, useEffect } from 'react'

export const useFetch = url => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchResources = async () => {

            try {
                let res = await fetch(url)
                let data = await res.json()
                const status = parseInt(data.status)
                if (status === 200) {
                    setData(data.result)
                    setLoading(false)
                } else {
                    setError("Error inesperado!")
                    setLoading(false)
                }

            } catch (error) {
                setError(error)
                setLoading(false)
            }
        }
        fetchResources()
    }, [url])

    return { data, loading, error }
}