import React, { useEffect, useState } from 'react';
import WAIcon from '../../assets/img/iconos-redes/whatsapp-icon.png';
import './Whatsapp.css';


const WhatsappIcon = () => {
    const [latir, setLatir] = useState(true)

    useEffect(() => {
        const latido = setInterval(() => {
            setLatir(!latir)
        }, 4000);

        return () => {
            clearInterval(latido)
        }
    }, [latir])

    return (
        <a href='https://api.whatsapp.com/send/?phone=5493537303217&text&type=phone_number&app_absent=0' target='_blank' rel='noreferrer'>
            <div style={{ position: "fixed", bottom: "1%", left: "2%", width: "70px", height: "70px", zIndex: 100 }} >
                <img src={WAIcon} alt='WhatsApp' className={latir ? "heart active" : "heart"} />
                <h3 className={latir ? "msg entrar" : "msg salir"} >Escribanos!</h3>
            </div>
        </a>
    )
}

export default WhatsappIcon
