import PropTypes from "prop-types";
import React, { Suspense, lazy } from "react";
import ScrollToTop from "./cba-baitcast/Components/helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home pages
const HomeLanding = lazy(() => import("./cba-baitcast/Pages/Home/Landing"));

// shop pages
const ShopGridStandardTag = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandardTags2"));
const ShopGridStandardCat = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandardCat2"));
const ShopGridStandardSubCat = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandardSubCat"));
const ShopGridStandardNew = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandardNew"));
const ShopGridStandardBs = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandardBs"));
const ShopGridStandardOffer = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandardOff"));
const ShopGridStandard = lazy(() => import("./cba-baitcast/Pages/Shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./cba-baitcast/Pages/Product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./cba-baitcast/Pages/Blog/BlogStandard"));
const BlogStandardTag = lazy(() => import("./cba-baitcast/Pages/Blog/BlogStandardTag"));
const BlogDetailsStandard = lazy(() =>
  import("./cba-baitcast/Pages/Blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./cba-baitcast/Pages/Other/About"));
const Contact = lazy(() => import("./cba-baitcast/Pages/Other/Contact"));
const MyAccount = lazy(() => import("./cba-baitcast/Pages/Other/MyAccount"));
const LoginRegister = lazy(() => import("./cba-baitcast/Pages/Other/LoginRegister"));
const Cart = lazy(() => import("./cba-baitcast/Pages/Other/Cart"));
const Wishlist = lazy(() => import("./cba-baitcast/Pages/Other/Wishlist"));
const Checkout = lazy(() => import("./cba-baitcast/Pages/Other/Checkout"));
const NotFound = lazy(() => import("./cba-baitcast/Pages/Other/NotFound"));

//Legales
const PoliticasPrivacidad = lazy(() => import("./cba-baitcast/Pages/Other/PolitPriv"));

const App = (props) => {

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeLanding}
                />

                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-tag/:tag"}
                  component={ShopGridStandardTag}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-sort/:cat"}
                  component={ShopGridStandardCat}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-subcat/:cat"}
                  component={ShopGridStandardSubCat}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-new"}
                  component={ShopGridStandardNew}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-off"}
                  component={ShopGridStandardOffer}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-bs/:bs"}
                  component={ShopGridStandardBs}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard-sort/"}
                  component={ShopGridStandard}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-standard"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-standard-tag/:tag"}
                  component={BlogStandardTag}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard/:id"}
                  component={BlogDetailsStandard}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout/"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/privacy-policies"}
                  component={PoliticasPrivacidad}
                />

                <Redirect
                  to={process.env.PUBLIC_URL + "/"}
                />

              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(App);
