require('dotenv').config()

var host = ""
const local = 0
if (process.env.NODE_ENV === "development") {
    if (local === 1) {
        host = "http://192.168.0.11:3015/test"
    } else {
        host = "https://nekoadmin.com.ar:3015/test"
    }
} else {
    host = "https://nekoadmin.com.ar:3015/test"
}
const UrlServerGral = host
const nvoUsu = UrlServerGral + "/nvousuario"
const initSesion = UrlServerGral + "/inisesion"
const iniSesionAuto = UrlServerGral + "/autologin"


const verificaEmail = UrlServerGral + "/verificaEmail"
const UpimgAvatarVendedor = UrlServerGral + "/img/compr/avatar"
const loguin = UrlServerGral + "/login"
const datosUsu = UrlServerGral + "/datosUsu"
const autologin = UrlServerGral + "/autologin"
const reenvioVerif = UrlServerGral + "/renvVerif"
const recPass = UrlServerGral + "/recPass"
const nvaPass = UrlServerGral + "/NvaPass"

const nvaCasilla = UrlServerGral + "/nvoEmail"


const blogFav = UrlServerGral + "/blog-fav"
const getCountdown = UrlServerGral + "/get-countdown"
const getHeroSlide = UrlServerGral + "/get-hero-slide"

const blogList = UrlServerGral + "/blog-list"
const blogSingle = UrlServerGral + "/blog-single/"

const contactData = UrlServerGral + "/contact-info"
const contactRedes = UrlServerGral + "/contact-redes"

const productList = UrlServerGral + "/product-list"
const categoryList = UrlServerGral + "/category-list"

const NuevaopinionProd = UrlServerGral + "/new-comment"

const ProvinciasList = UrlServerGral + "/ProvinciasList"
const CalcularEnvio = UrlServerGral + "/calcularEnvio"
const CiudadesList = UrlServerGral + "/CiudadesList"
const SucursalesList = UrlServerGral + "/SucursalesList"

const FeedBackMP = UrlServerGral + "/Feedback"
const CodigoPostal = UrlServerGral + "/CodigoPostal"
const ModInfoPersonalUsu = UrlServerGral + "/ModInfoPersonalUsu"
const ModInfoDirection = UrlServerGral + "/ModInfoDirection"
const NuevoPedido = UrlServerGral + "/NuevoPedido"

const PedidosPendientes = UrlServerGral + "/PedidosPend"
const CancelarPedido = UrlServerGral + "/CancelarPedido"
const DetPedidoPend = UrlServerGral + "/DetPedidoPend"
const PedidosCompletos = UrlServerGral + "/PedidosCompletos"
const CrearFactura = UrlServerGral + "/CrearFactura"
const EnvioFactura = UrlServerGral + "/EnvioFactura"
const CrearNotaCred = UrlServerGral + "/CrearNotaCred"

const RecpassUsu = UrlServerGral + "/recpassUsu2"
const CambioPassUsu = UrlServerGral + "/CambioPassUsu"

const SuscrNews = UrlServerGral + "/SuscrNews"

const ContactMe = UrlServerGral + "/ContactMe"

const ReenviarEmailConfirm = UrlServerGral + "/ReenviarEmailConfirm"

const AplicarCupon = UrlServerGral + "/aplicarCupon/"
const CorroboraCupon = UrlServerGral + "/corroboraCupon/"

const CrearPreferencia = UrlServerGral + "/createPreference"

const GetLocation = UrlServerGral + "/GetLocation"


const UrlNodeServer = {
    urlGeneral: UrlServerGral,
    nvoUsu: nvoUsu,
    verificaEmail: verificaEmail,
    UpimgAvatarVendedor: UpimgAvatarVendedor,
    loguin: loguin,
    datosUsu: datosUsu,
    autologin: autologin,
    renvVerif: reenvioVerif,
    recPass: recPass,
    nvaPass: nvaPass,
    nvoEmail: nvaCasilla,
    blogFav: blogFav,
    blogList: blogList,
    blogSingle: blogSingle,
    contactData: contactData,
    contactRedes: contactRedes,
    getCountdown: getCountdown,
    getHeroSlide: getHeroSlide,
    productList: productList,
    categoryList: categoryList,
    NuevaopinionProd: NuevaopinionProd,
    initSesion: initSesion,
    iniSesionAuto: iniSesionAuto,
    ProvinciasList: ProvinciasList,
    CalcularEnvio: CalcularEnvio,
    CiudadesList: CiudadesList,
    SucursalesList: SucursalesList,
    FeedBackMP: FeedBackMP,
    CodigoPostal: CodigoPostal,
    ModInfoPersonalUsu: ModInfoPersonalUsu,
    ModInfoDirection: ModInfoDirection,
    NuevoPedido: NuevoPedido,
    PedidosPendientes: PedidosPendientes,
    CancelarPedido: CancelarPedido,
    DetPedidoPend: DetPedidoPend,
    PedidosCompletos: PedidosCompletos,
    CrearFactura: CrearFactura,
    EnvioFactura: EnvioFactura,
    CrearNotaCred: CrearNotaCred,
    RecpassUsu: RecpassUsu,
    CambioPassUsu: CambioPassUsu,
    SuscrNews: SuscrNews,
    ContactMe: ContactMe,
    ReenviarEmailConfirm: ReenviarEmailConfirm,
    AplicarCupon: AplicarCupon,
    CrearPreferencia: CrearPreferencia,
    CorroboraCupon: CorroboraCupon,
    GetLocation: GetLocation
}

export default UrlNodeServer