import React, { useEffect } from 'react'
import { useFetch } from './cba-baitcast/Hooks/useFetch'
import NodeServer from './cba-baitcast/Globals/NodeServer'
import { composeWithDevTools } from "redux-devtools-extension";
import { fetchProducts } from "./cba-baitcast/redux/actions/productActions";
import rootReducer from "./cba-baitcast/redux/reducers/rootReducer";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import App from './App'
import { Spinner } from 'react-bootstrap'
import WhatsappIcon from './cba-baitcast/Components/WhatsappIcon';

const App2 = () => {
  const { data, error, loading } = useFetch(NodeServer.productList)
  const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
  );
  // fetch products from json file
  useEffect(() => {
    if (!loading) {
      if (!error) {
        store.dispatch(fetchProducts(data));
      }
    }
    // eslint-disable-next-line
  }, [loading])

  if (!loading) {
    if (!error) {
      return (
        <Provider store={store}>
          <App />
          <WhatsappIcon />
        </Provider>
      )
    } else {
      window.location.replace("https://mant.cordobabaitcast.com.ar/")
    }
  } else {
    return (
      <div className="container" style={{ textAlign: "center" }}>
        <Spinner animation="grow" role="status" variant="warning">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }
};

export default App2;
